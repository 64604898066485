export const menuItems = [
  {
    name: '',
    label: 'Home',
  },
  {
    name: 'about',
    label: 'About',
  },
  {
    name: 'blog',
    label: 'Blog',
  },
  {
    name: 'contact',
    label: 'Contact',
  },
  {
    name: 'https://app.ustrive.com',
    label: 'Login',
    isExternal: true,
  },
];
