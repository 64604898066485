import React from 'react';
import { Link } from 'gatsby';

import imgLB from '../../assets/image/logo-main-black.png';
import imgLW from '../../assets/image/logo-main-white.png';
import imgFS from '../../assets/image/inner-page/png/yellow-footer-shape.png';

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={`bg-dark-cloud pt-13 pt-lg-27 pb-7 dark-mode-texts position-relative ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="row pb-lg-25">
            <div className="col-lg-4 col-md-5 col-md-3 col-xs-8">
              <div className="pr-xl-20 mb-11 mb-lg-0 mt-lg-5">
                <div className="brand-logo mb-5">
                  <Link to="/">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt="UStrive Logo"
                    />
                    <img
                      src={imgLW}
                      alt="UStrive Logo"
                      width="180"
                      className="dark-version-logo mx-auto mx-0"
                    />
                  </Link>
                </div>
                <p className="font-size-5 mb-0 text-bali-gray pr-sm-10 pr-md-0">
                  We connect individuals with mentors to help them achieve their
                  college and career dreams!
                </p>
                <div className="mt-8">
                  <Link
                    to="/contact"
                    className="btn btn-blue-3 btn-sm rounded-5 text-lily-white font-size-3"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 offset-xl-1">
              <div className="row">
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Main Menu
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <Link
                          to="/"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/about"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          About
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/blog"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Blog
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/contact"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Contact
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/topics"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Topics
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-md-3 col-xs-6">
                  <div className="mb-10 mb-lg-0">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      For Users
                    </h4>
                    <ul className="list-unstyled">
                      <li className="mb-6">
                        <a
                          href="https://app.ustrive.com/students"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Register
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          href="https://app.ustrive.com/login"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Login
                        </a>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/terms"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Terms of Use
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          to="/privacy"
                          className="font-size-5 text-lily-white gr-hover-text-blue-3"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widgets */}
                {/* Single Widgets */}
                <div className="col-lg-6 col-md-5 col-xs-8">
                  <div className="mb-10 mb-lg-0 mr-xl-12">
                    <h4 className="font-size-6 font-weight-normal mb-8 text-bali-gray">
                      Contact Us
                    </h4>
                    <p className="font-size-5 mb-0 text-lily-white">
                      Do you have any questions before getting started? Reach
                      out to us!
                    </p>
                    <div className="mt-7">
                      <a
                        className="font-size-5 d-block text-golden-yellow mb-0"
                        href="mailto:support@ustrive.com"
                      >
                        support@ustrive.com
                      </a>
                    </div>
                    <div className="mt-7">
                      <a
                        rel="noopener noreferrer"
                        className="font-size-5 d-block text-golden-yellow mb-0"
                        href="https://www.instagram.com/ustrive"
                        target="_blank"
                      >
                        @ustrive
                      </a>
                    </div>
                  </div>
                </div>
                {/* End Single Widgets */}
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-10 border-top">
            <div className="col-lg-6 text-center text-lg-left">
              <div className="copyright">
                <p className="mb-0 font-size-3 text-bali-gray">
                  © UStrive 2022, All Rights Reserved
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-right">
              <div className="footer-right mt-5 mt-lg-0">
                <ul className="list-unstyled d-flex align-items-center justify-content-center justify-content-lg-end mb-0">
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/ustrive"
                      target="_blank"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/ustrive"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white gr-hover-text-blue-3 ml-7"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/UStrive-105806421489341"
                      className="text-white gr-hover-text-blue-3 ml-7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/Strive4C"
                      className="text-white gr-hover-text-blue-3 ml-7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-yellow-shape-img">
          <img src={imgFS} alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
